<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
        v-if="getEmployeeDetails"
        :title="
          getEmployeeDetails.status == 2
            ? $t('users_details.new_account_title')
            : $t('users_details.employee_title')
        "
        :sub-title="
          getEmployeeDetails.status == 2
            ? $t('users_details.new_account_sub_title')
            : $t('users_details.employee_sub_title')
        "
      />
    </div>
    <section class="wameed-dashboard-page-content_body mt-3">
      <div class="mx-4">
        <b-row class="">
          <b-col
            md="10"
            class="my-md-4 my-0"
          >
            <user-details-table
              v-if="getEmployeeDetails"
              :data-employee="getEmployeeDetails"
            />
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserDetailsTable from '@/components/pages/users/UserDetailsTable.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: {
    UserDetailsTable,
    PageHeader,
    WameedBtn,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getEmployeeDetails: 'getEmployeeDetails',
    }),
  },
  mounted() {
    this.loadEmployeesDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadEmployeesDetails: 'loadEmployeesDetails',
      loadFile: 'loadFile',
    }),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
